import Section from '../Section'
import ResponsiveImage from '../ResponsiveImage'
import RichContent from '../RichContent'
import { theme } from '../../styles/theme'
import StyledLink from '../StyledLink'
import useScrollTrigger from '../../hooks/useScrollTrigger'
import { createUseStyles } from '../../helpers/createStyles'

export default function FullWidthImage ({ data }) {
  const { title, copy, cta, desktop_image: desktopImage, mobile_image: mobileImage } = data
  const styles = useStyles()

  const imageRef = useScrollTrigger(
    () => ({
      trigger: imageRef.current.parentNode,
      start: 'top bottom',
      end: 'bottom top'
    }),
    (tl, ref) => {
      tl.fromTo(imageRef.current, { y: '-10%' }, { y: '10%', ease: 'none' })
    }
  )

  const sectionRef = useScrollTrigger(
    () => ({
      trigger: sectionRef.current,
      start: () => `${window.innerHeight / 4}px bottom`,
      scrub: false
    }),
    (tl, ref) => {
      const defaults = {
        ease: 'power2.inOut',
        duration: 0.8
      }
      tl.from(ref.current.children[0], { opacity: 0, ...defaults })
      tl.from(ref.current.querySelectorAll(`.${styles.contentInner} > *`), { y: 40, opacity: 0, stagger: 0.2, ...defaults }, '-=0.8')
    }
  )

  return (
    <Section className={styles.section} fullWidth ref={sectionRef}>
      <ResponsiveImage ref={imageRef} image={desktopImage} mobileImage={mobileImage} loading='lazy' aspect={1440 / 900} mobileAspect={375 / 626} />
      <Section tag='div' className={styles.content} grid noBottomMargin>
        <div className={styles.contentInner}>
          {title && <h2 className={styles.title}>{title}</h2>}
          {copy && <RichContent className={styles.copy} content={copy} />}
          {cta && <StyledLink link={cta} />}
        </div>
      </Section>
    </Section>
  )
}

const useStyles = createUseStyles({
  section: {
    color: theme.colors.white,
    position: 'relative',
    overflow: 'hidden',
    zIndex: theme.zIndex.image
    // maxHeight: '100vh'
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: 'center'
  },
  contentInner: {
    gridColumn: '1 / -1',
    maxWidth: '420px',
    width: '100%',
    zIndex: theme.zIndex.imageContent,
    [theme.breakpoints.up('md')]: {
      gridColumn: '5 / -1'
    }
  },
  title: {
    marginBottom: theme.spacingPx(8),
    fontSize: '40px'
  },
  copy: {
    marginBottom: theme.spacingPx(6)
  }
})
